.content-box.contact-form {
  text-align: center;
  background: #16222a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3a6073,
    #16222a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3a6073,
    #16222a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
}

.contact-form-box {
  color: white;
  text-align: left;
  margin: auto;
  margin-top: 20px;
  max-width: 600px;
}

.contact-form-input {
  font-size: 15px;
  margin-top: 20px;
  font-family: "Blazma";
}

.contact-form-input .mantine-TextInput-label {
  color: white;
  font-size: 18px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.contact-form-input .mantine-Textarea-label {
  color: white;
  font-size: 18px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.contact-form-select {
  font-size: 15px;
  margin-top: 20px;
  font-family: "Blazma";
}

.contact-form-select .mantine-Select-label {
  color: white;
  font-size: 18px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.contact-form-radio-grp {
  margin-top: 20px;
  font-family: "Blazma";
}

.contact-form-radio {
  margin-top: 5px;
  margin-left: 15px;
}

.contact-form-radio .mantine-Radio-label {
  color: white;
  font-size: 15px;
}

.contact-form-btn:hover {
  transform: scale(1.15);
}

.contact-btn.contact-form {
  background: #02aab0 !important; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #00cdac,
    #02aab0
  ) !important; /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #00cdac,
    #02aab0
  ) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
