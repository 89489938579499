.cooh-div {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #284e13;
  text-align: center;
  font-size: 16px;
  font-family: "Glametrix";
  position: relative;
  z-index: 200;
}

.cooh-title {
  width: 100%;
  margin: auto;
  font-size: max(10vw, 80px);
  font-family: "Aileron";
}

.cooh-img-grp {
  width: 100%;
  max-height: 500px;
  aspect-ratio: 2/1;
  margin-top: 20px;
  background: #f1f2b5; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom right,
    #135058,
    #f1f2b5
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom right,
    #135058,
    #f1f2b5
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  padding: 10px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cooh-img {
  width: 45%;
  height: 100%;
  object-fit: contain;
  margin: auto;
}

.cooh-content-div {
  margin-top: 20px;
  border-radius: 15px;
  border: 2px solid black;
  padding: 20px;
  text-align: left;
}

.content-title.cooh {
  text-align: center;
}

.content-subtitle.cooh {
  text-align: center;
  margin-top: 15px;
}
