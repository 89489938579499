.content-title.contact-us {
  font-size: 50px;
}

.content-box.contact-us {
  max-width: 500px;
  margin: auto;
  margin-top: 20px;
}

.contact-us-text {
  font-size: 20px;
  margin-top: 30px;
  text-align: justify;
  font-family: "Pro Pixie";
}

.contact-us-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
}

.contact-btn.contact-us {
  width: 270px;
  font-size: 18px;
}
