.footer-div {
  background: #1f4037; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #99f2c8,
    #1f4037
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #99f2c8,
    #1f4037
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  min-height: 5vh;
  padding: 20px;
  padding-bottom: 5px;
  position: relative;
  z-index: 999;
}

.contact-anchor {
  text-decoration: none;
}

.contact-box {
  min-width: 300px;
  margin-top: 20px;
}

.add-box {
  min-width: 300px;
  background-color: rgba(255, 255, 255, 0.319);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  padding: 30px;
  margin-top: 20px;
}

.add-text {
  margin-top: 20px;
  font-size: 16px;
  font-family: "Jost";
}

.contact-box-1 {
  text-align: center;
  overflow: hidden;
  max-height: 250px;
}

.contact-link-title {
  font-size: 40px;
  font-family: "Jost";
}

.contact-link-1 {
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-decoration: none;
  color: white;
  font-family: "Jost";
}

.contact-link-1:hover {
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 10px;
}

.contact-icon {
  cursor: pointer;
  margin-top: 20px;
}

.contact-icon:hover {
  transform: scale(1.2);
}

#footer-img {
  height: 250px;
}

.footer-copyright-grp {
  margin-top: 20px;
}

#footer-logo {
  height: 70px;
}

#footer-copyright {
  text-align: center;
  font-size: 15px;
  font-family: "Jost";
}
