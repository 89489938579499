* {
  margin: 0;
  padding: 0;
  font-family: "Jost";
}

@font-face {
  font-family: "Aileron";
  src: url(./fonts/Aileron-Black.otf);
}

@font-face {
  font-family: "Lora Italic";
  src: url(./fonts/Lora-Italic-VariableFont_wght.ttf);
}

@keyframes gelatine {
  from,
  to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}

@keyframes hithere {
  30% {
    transform: scale(1.2);
  }
  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }
  50% {
    transform: rotate(20deg) scale(1.2);
  }
  70% {
    transform: rotate(0deg) scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.app-body {
  height: 100%;
  min-height: 80vh;
  background-color: #bec5c1;
  font-family: "Jost";
}

.page-title {
  font-size: 40px;
}

.under-bold {
  font-weight: bold;
  text-decoration: underline;
}

.app-bg {
  position: fixed;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.7;
}

/*************************Content****************************/
.content-comp-div {
  padding: 20px;
  /* padding-bottom: 50px; */
  background-color: rgba(255, 255, 255, 0.9);
  color: #284e13;
  text-align: center;
  font-size: 16px;
  /* font-family: "Glametrix"; */
  position: relative;
  z-index: 200;
  font-family: "Jost";
  height: max-content;
}

.content-box {
  padding: 20px;
}

.content-box.border {
  border-radius: 15px;
  border: 2px solid black;
}

.content-box.top-margin {
  margin-top: 20px;
}

.content-txt {
  font-family: "Jost", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 15px;
  text-align: left;
}

.content-title {
  font-size: 35px;
  font-family: "Aileron";
}

.content-title.monotone {
  font-family: "Monoton", sans-serif;
}

.content-title.italic {
  font-size: 40px;
  font-family: "Caveat", cursive;
}

.content-subtitle {
  font-size: 25px;
  font-family: "Aileron";
}

.contact-btn {
  font-size: 25px !important;
  height: max-content !important;
  width: 100% !important;
  max-width: 500px !important;
  padding: 20px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-top: 20px !important;
  background: #16222a !important; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3a6073,
    #16222a
  ) !important; /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3a6073,
    #16222a
  ) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

/*************************Accordion****************************/
.content-accordion {
  margin: auto;
  margin-top: 5px;
  max-width: 800px;
}

.content-accordion-item {
  position: relative;
}

.content-accordion-control {
  margin-top: 15px;
  color: white !important;
  border-radius: 99px;
  background: #16222a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3a6073,
    #16222a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3a6073,
    #16222a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: relative;
  z-index: 105;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.content-accordion-title {
  /* font-family: "Aileron"; */
  font-size: 16px;
  font-weight: bold;
}

.content-accordion-panel {
  background: #348f50; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #56b4d3,
    #348f50
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #56b4d3,
    #348f50
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: white;
  margin: auto;
  text-align: left;
  font-size: 16px;
  padding: 10px;
  padding-top: 35px;
  margin-top: -25px;
  position: relative;
  z-index: 104;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 2px solid #16222a;
}

/****************top image***************/
.top-img-comp {
  position: relative;
  /* height: calc(100vh - 90px); */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
}

.top-img-div {
  height: 100%;
  max-width: 1280px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  animation: fade-in 800ms linear;
  object-fit: cover;
}

.top-img {
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
}

.top-img.full-width {
  width: 100%;
  object-fit: cover;
}

.top-img-title {
  font-size: 40px;
  text-align: center;
  font-family: "Aileron";
  position: inherit;
  width: fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 10px;
  margin-right: 10px;
  z-index: 101;
  padding: 20px;
  border-radius: 15px;
  background: #16222a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3a6073,
    #16222a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3a6073,
    #16222a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.top-img-scroll-down-div {
  position: absolute;
  z-index: 101;
  aspect-ratio: 1/1;
  bottom: 20px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background: #16222a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3a6073,
    #16222a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3a6073,
    #16222a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 50%;
  transition-duration: 500ms;
}

.top-img-scroll-down-div.hide {
  opacity: 0;
}

.top-img-scroll-down {
  width: 30px;
  margin-bottom: -10px;
}

/*links*/
.link-anchor {
  text-decoration: none;
}
