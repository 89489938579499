.top-div.hide {
  display: none;
}

.top-div {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 50px;
  right: 15px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 8px;
  cursor: pointer;
  z-index: 1000;
}

.to-top-img {
  width: 28px;
}

.top-text {
  color: #0b7141;
  font-size: 12px;
}

.top-arrow {
  animation: bounce 2s ease infinite;
}
