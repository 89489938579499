.nav-modal-content {
  height: 100%;
  background: #134e5e; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #71b280,
    #134e5e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #71b280,
    #134e5e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.nav-modal-close {
  cursor: pointer;
}

.nav-modal-close:hover {
  background-color: transparent !important;
  animation: gelatine 1s ease infinite;
}

.nav-modal-logo-grp {
  position: relative;
  width: 200px;
  aspect-ratio: 1/1;
  margin: auto;
  border-radius: 50%;
}

#nav-modal-logo {
  width: 200px;
  position: absolute;
  z-index: 500;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.nav-modal-logo-border {
  width: 200px;
  position: absolute;
  z-index: 501;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  box-shadow: 4px -5px 7px 5px #4b7856 inset;
  animation: spin 2s infinite linear;
  font-size: 0;
}

.nav-modal-box {
  width: fit-content;
  margin: auto;
  margin-top: 50px;
}

.nav-modal-links.main {
  text-align: left;
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  color: #beffcd;
}

.nav-modal-links.main .mantine-NavLink-label {
  font-size: 18px;
}

/* .nav-modal-links.main:hover {
  background-color: transparent !important;
  text-decoration: underline;
} */

.nav-modal-links.sub {
  text-align: right;
  color: #beffcd;
  margin-top: 10px;
}

.nav-modal-links.sub .mantine-NavLink-label {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.nav-modal-links:hover {
  background-color: transparent !important;
}

.nav-modal-links:hover .nav-link-icon {
  animation: hithere 1s ease infinite;
}
