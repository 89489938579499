.location-box {
  padding: 20px;
  border-radius: 15px;
  border: 2px solid black;
}

.location-box.mid {
  margin-top: 20px;
}

.location-box.btm {
  margin-top: 20px;
  border: none;
}

.location-box-content.title {
  font-size: 35px;
}

.location-accordion {
  margin: auto;
  margin-top: 5px;
  max-width: 800px;
}

.location-accordion-item {
  position: relative;
}

.location-accordion-control {
  margin-top: 15px;
  color: white !important;
  border-radius: 99px;
  background: #16222a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3a6073,
    #16222a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3a6073,
    #16222a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: relative;
  z-index: 105;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.location-accordion-title {
  /* font-family: "Aileron"; */
  font-size: 16px;
  font-weight: bold;
}

.location-accordion-panel {
  background: #348f50; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #56b4d3,
    #348f50
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #56b4d3,
    #348f50
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: white;
  margin: auto;
  text-align: left;
  font-size: 16px;
  padding: 10px;
  padding-top: 35px;
  margin-top: -25px;
  position: relative;
  z-index: 104;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 2px solid #16222a;
}

.location-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 350px));
  grid-gap: 3%;
  justify-content: center;
  padding: 30px;
}

.location-card {
  width: 100%;
  background-color: transparent;
  height: 100%;
  /* overflow: visible; */
}

.location-card-img {
  width: 100%;
  object-fit: contain;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.location-card-title {
  margin-top: 15px;
  font-size: 25px;
}

.content-txt.location-content {
  margin-top: 10px;
}
