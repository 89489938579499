.affordable-img {
  width: 100%;
  aspect-ratio: 10/9;
  object-fit: cover;
  margin-top: 20px;
}

.affordable-content-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 350px));
  grid-gap: 3%;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.affordable-content-box {
  width: calc(100%-30px);
  border-radius: 15px;
  background: #16222a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3a6073,
    #16222a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3a6073,
    #16222a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 15px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.affordable-content {
  text-align: left;
}

.affordable-content.title {
  font-size: 35px;
  text-align: center !important;
}

.affordable-content.txt {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 15px;
}

.affordable-content.box-title {
  font-size: 28px;
}

.affordable-content.box-txt {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 15px;
}

.affordable-content-btn {
  margin-top: 20px !important;
  background: #02aab0 !important; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #00cdac,
    #02aab0
  ) !important; /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #00cdac,
    #02aab0
  ) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #27464d !important;
  font-weight: 900 !important;
  font-size: 18px !important;
}

/* .affordable-content-btn .mantine-Button-label {
  font-family: "Aileron";
} */

.affordable-content-btn:hover {
  background: #02aab0; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #00cdac,
    #02aab0
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #00cdac,
    #02aab0
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #27464d;
}

.content-subtitle.affordable {
  text-align: left;
}

.content-txt.affordable {
  margin-top: 15px;
}
