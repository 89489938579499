.header-div {
  background: #1f4037; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom right,
    #99f2c8,
    #1f4037
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom right,
    #99f2c8,
    #1f4037
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  height: 90px;
  position: sticky;
  top: 0;
  z-index: 900;
  vertical-align: middle;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-div.hide {
  top: -90px;
}

#header-logo {
  height: 75px;
  margin-left: 20px;
  margin-right: auto;
  cursor: pointer;
}

.header-burger {
  margin-left: auto;
  margin-right: 20px;
}
