.page-div {
  margin: auto;
  width: 100%;
  max-width: 1280px;
  position: relative;
}

.page-div.home {
  background-image: url("https://res.cloudinary.com/dwectnni1/image/upload/v1703145373/Website/SFA/Images/Website2.0/COOH/Copy_of_DALL_E_2023-12-18_10.53.47_-_Create_a_wordless_realistic_and_futuristic_graphic_advertisement_that_embodies_a_commitment_to_affordable_public_EV_charging_subsidized_by_ad_revenu_nludmn.png");
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hash-link-div {
  width: 100%;
  background-color: white;
  margin-top: 0;
  /* border-bottom: 1px solid #0b7141; */
}

.hash-link-div.green {
  background-color: #0b7141;
  color: white;
}

.jump-to {
  width: fit-content;
  margin: auto;
  font-weight: bold;
  font-size: 18px;
  color: #0b7141;
}

.jump-to.green {
  color: white;
}

.jump-to:hover {
  background-color: transparent !important;
}

.jump-to .mantine-NavLink-label {
  font-size: 15px;
  /* font-family: "Pro Pixie"; */
}

.hash-links-box {
  padding: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hash-links-box.green {
  background-color: #0b7141;
}

.hash-links-btn {
  text-decoration: none;
  color: #0b7141;
  font-weight: bold;
  font-size: 20px;
  padding: 5px;
  text-align: center;
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
  /* border-radius: 15px; */
  /* font-family: "Glametrix"; */
}

.hash-links-btn.green {
  color: white;
}

.top-div {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 50px;
  right: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}

.top-text {
  color: #0b7141;
  font-size: 12px;
}
