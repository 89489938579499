.future-projects-timeline {
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-top: 30px;
}

.future-projects-box {
  width: 40%;
  aspect-ratio: 1/1;
  max-height: 100px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  background-color: #0b7141;
  padding: 10px;
  border-radius: 15px;
  position: relative;
  border: none;
}

.future-projects-box.left {
  margin-left: 20px;
  margin-right: auto;
}

.future-projects-box.right {
  margin-right: 20px;
  margin-left: auto;
}

.future-projects-box.navigate {
  cursor: pointer;
}

.future-projects-box.navigate:hover {
  transform: scale(1.1);
}

.future-projects-img {
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
}

.future-projects-img.top-left {
  left: 0;
  top: 0;
}

.future-projects-img.timeline-2 {
  margin-top: 50px;
  border-bottom: 2px solid black;
}

.future-projects-btm-tri {
  position: absolute;
  left: 30px;
  margin: 0 auto;
  bottom: -14px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #0b7141;
}

.future-projects-top-tri {
  position: absolute;
  right: 30px;
  margin: 0 auto;
  top: -14px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #0b7141;
}
