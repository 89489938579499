.ev-app-grp {
  width: 100%;
  margin: auto;
  margin-top: 20px;
}

.ev-app-box {
  width: 250px;
}

.ev-app-img {
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 15px;
}

.ev-app-img-phone {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
}

.ev-app-img:hover {
  transform: scale(1.1);
}

.app-img {
  width: 80%;
  max-width: 300px;
  margin-top: 20px;
}

.ev-grid {
  margin-top: 20px;
  justify-content: center;
  flex-grow: 1;
}

.ev-carousel {
  /* padding: 20px; */
  margin: auto;
  margin-top: 15px;
  width: 100%;
  max-width: 600px;
  padding: 0 !important;
}

.ev-content-box {
  width: calc(100% - 10px);
  height: 100%;
  margin: auto;
  /* min-width: 350px; */
  border-radius: 15px;
  border: 2px solid black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 40px;
}

.ev-content-img {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 200px;
  border-radius: 3%;
  opacity: 1;
  object-fit: contain;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.ev-content-icon {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
}

.content-subtitle.ev {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ev-carousel.ev-carousel-indicator {
  width: rem(12px);
  height: rem(4px);
  transition: width 250ms ease;

  &[data-active] {
    width: rem(40px);
  }
}
